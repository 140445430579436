<template>
    <div class="w-full px-side bg-white py-4 z-40">
        <div class="flex items-center justify-between gap-8">
            <div
                class="flex gap-8"
                :class="[
                    props.layout === 2 ? 'flex-col' : 'flex-row items-center',
                ]"
            >
                <a class="cursor-pointer" href="/">
                    <img
                        :class="[
                            {
                                'max-h-[2.5rem] max-w-[7rem]':
                                    CONFIG_OPTIONS.header.logoSize === 'small',
                            },
                            {
                                'max-h-[3.5rem] max-w-[9rem]':
                                    CONFIG_OPTIONS.header.logoSize === 'normal',
                            },
                            {
                                'max-h-[5rem] max-w-[12rem]':
                                    CONFIG_OPTIONS.header.logoSize === 'large',
                            },
                        ]"
                        :src="props.data.bild[0].url"
                        :alt="props.data.bild[0].alt"
                    />
                </a>
                <div class="hidden minL:flex gap-8">
                    <template v-for="nav in props.data.navigation">
                        <a
                            v-if="
                                nav.__typename == 'navigation_navlink_BlockType'
                            "
                            :href="nav.linkfeld.url"
                            class="text-regular"
                        >
                            {{ nav.linkfeld.text }}
                        </a>

                        <div
                            class="cursor-pointer text-regular"
                            :class="{
                                'font-bold underline underline-offset-[16px] decoration-2':
                                    activeMenu === nav.id,
                            }"
                            v-else
                            @click="toggleMenu(nav.id)"
                        >
                            {{ nav.ueberschrift }}
                        </div>
                    </template>
                </div>
            </div>

            <div
                class="flex gap-x-4 gap-y-8"
                :class="[
                    props.layout === 2
                        ? 'flex-col items-end'
                        : 'flex-row items-center',
                ]"
            >
                <div class="hidden minM:flex gap-2 text-small">
                    <a
                        :href="kleinerLink.linkfeld.url"
                        v-for="kleinerLink in props.data.kleineLinks"
                        class="border-r border-black pr-2 gap-1 flex items-center"
                    >
                        <i
                            class="text-large"
                            v-if="kleinerLink.iconName"
                            :class="[
                                CONFIG_OPTIONS.global.iconStyle,
                                'fa-' + kleinerLink.iconName,
                            ]"
                        ></i>
                        {{ kleinerLink.linkfeld.customText }}
                    </a>

                    <a href="" class="border-r border-black pr-2">
                        <i
                            class="fa-magnifying-glass text-large"
                            :class="[CONFIG_OPTIONS.global.iconStyle]"
                        ></i>
                    </a>
                    <div class="">
                        DE
                        <i
                            :class="[
                                CONFIG_OPTIONS.header.languageSwitcherIcon,
                            ]"
                        ></i>
                    </div>
                </div>

                <div class="hidden minM:flex gap-2">
                    <a
                        :href="button.linkfeld.url"
                        v-for="(button, index) in props.data.headerButtons"
                        class="flex gap-2 items-center"
                        :class="[
                            index === 0
                                ? CONFIG_OPTIONS.header.secondaryButtonClass
                                : CONFIG_OPTIONS.header.primaryButtonClass,
                        ]"
                    >
                        <i
                            v-if="button.iconName"
                            :class="[
                                CONFIG_OPTIONS.global.iconStyle,
                                'fa-' + button.iconName,
                            ]"
                        ></i>
                        {{ button.linkfeld.customText }}
                    </a>
                </div>

                <i
                    class="cursor-pointer block minL:hidden"
                    :class="[
                        CONFIG_OPTIONS.global.iconStyle,
                        mobileMenuOpen
                            ? 'fa-times'
                            : CONFIG_OPTIONS.header.menuIcon,
                    ]"
                    @click="toggleMobileMenu"
                ></i>
            </div>
        </div>
    </div>
    <div class="z-40 relative">
        <div
            class="absolute w-full px-side bg-white grid-cols-3 gap-20 py-16 z-40"
            v-for="dropdownMenu in dropdownMenues"
            :class="{
                hidden: activeMenu !== dropdownMenu.id,
                'hidden minM:grid': activeMenu === dropdownMenu.id,
            }"
        >
            <div class="border-black border-r flex flex-col gap-8">
                <template v-for="child in dropdownMenu.children">
                    <a
                        v-if="
                            child.__typename == 'navigation_navlink_BlockType'
                        "
                        :href="child.linkfeld.url"
                        class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                    >
                        {{ child.linkfeld.text }}
                    </a>
                    <div
                        class="cursor-pointer text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                        :class="{
                            'text-color-primary underline underline-offset-[16px]':
                                activeSubMenu === child.id,
                        }"
                        v-else
                        @click="toggleSubMenu(child.id)"
                    >
                        {{ child.ueberschrift }}
                    </div>
                </template>
            </div>
            <div class="">
                <template v-for="child in dropdownMenu.children">
                    <div
                        class="flex flex-col gap-8"
                        :class="{ hidden: activeSubMenu !== child.id }"
                        v-if="
                            child.__typename ==
                            'navigation_subNavgruppe_BlockType'
                        "
                    >
                        <a
                            :href="subChild.linkfeld.url"
                            class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                            v-for="subChild in child.children"
                        >
                            {{ subChild.linkfeld.text }}
                        </a>
                    </div>
                </template>
            </div>
            <div class="">
                <div
                    v-if="
                        dropdownMenu.highlightBild.length > 0 ||
                        dropdownMenu.lead ||
                        dropdownMenu.text ||
                        dropdownMenu.linkfeld.url ||
                        dropdownMenu.untertitel
                    "
                    class="p-6 relative"
                    :class="[CONFIG_OPTIONS.header.bgColor]"
                >
                    <div
                        v-if="dropdownMenu.untertitel"
                        class="mb-2 text-small-700"
                    >
                        {{ dropdownMenu.untertitel }}
                    </div>

                    <img
                        v-if="dropdownMenu.highlightBild.length > 0"
                        class="w-full mb-4"
                        :src="dropdownMenu.highlightBild[0].url"
                        :alt="dropdownMenu.highlightBild[0].alt"
                    />

                    <div v-if="dropdownMenu.lead" class="mb-1 text-regular-700">
                        {{ dropdownMenu.lead }}
                    </div>
                    <div v-if="dropdownMenu.text" class="mb-4 text-small">
                        {{ dropdownMenu.text }}
                    </div>
                    <a
                        v-if="dropdownMenu.linkfeld.url"
                        :href="dropdownMenu.linkfeld.url"
                        class="text-small"
                    >
                        {{ dropdownMenu.linkfeld.text }}
                        <i
                            class="fa-caret-right"
                            :class="[CONFIG_OPTIONS.global.iconStyle]"
                        ></i>
                    </a>
                    <div
                        v-if="CONFIG_OPTIONS.global.blockDecoration.enabled"
                        class="decoration-offset w-full"
                    ></div>
                    <div
                        v-if="CONFIG_OPTIONS.global.blockDecoration.enabled"
                        class="absolute bottom-0 left-0 right-0 !h-6"
                        :class="[
                            'decoration-' +
                                CONFIG_OPTIONS.global.blockDecoration.style +
                                '-inverse',
                        ]"
                    ></div>
                </div>
            </div>
        </div>
    </div>

    <div class="z-40 relative">
        <div
            class="absolute w-full px-side bg-white flex flex-col gap-8 py-16"
            :class="{ hidden: !mobileMenuOpen }"
        >
            <template v-for="nav in props.data.navigation">
                <a
                    v-if="nav.__typename == 'navigation_navlink_BlockType'"
                    :href="nav.linkfeld.url"
                    class="text-f5 font-secondary !font-bold !not-italic"
                    :class="{ hidden: activeMenu && activeMenu !== nav.id }"
                >
                    {{ nav.linkfeld.text }}
                </a>

                <div
                    class="cursor-pointer text-f5 font-secondary !font-bold !not-italic"
                    :class="{
                        'underline underline-offset-[16px] decoration-2 text-color-primary pb-12':
                            activeMenu === nav.id && !activeSubMenu,
                        'text-black opacity-20':
                            activeMenu === nav.id && activeSubMenu,
                    }"
                    v-else
                    @click="toggleMenu(nav.id)"
                >
                    {{ nav.ueberschrift }}
                </div>
                <div
                    class="flex flex-col gap-8"
                    :class="{ hidden: activeMenu !== nav.id }"
                >
                    <template v-for="subchild in nav.children">
                        <a
                            v-if="
                                subchild.__typename ==
                                'navigation_navlink_BlockType'
                            "
                            :href="subchild.linkfeld.url"
                            class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                            :class="{
                                hidden:
                                    activeSubMenu &&
                                    activeSubMenu !== subchild.id,
                            }"
                        >
                            {{ subchild.linkfeld.text }}
                        </a>
                        <div
                            class="cursor-pointer text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                            :class="{
                                'text-color-primary underline underline-offset-[16px] pb-12 decoration-2':
                                    activeSubMenu === subchild.id,
                                hidden:
                                    activeSubMenu &&
                                    activeSubMenu !== subchild.id,
                            }"
                            v-else
                            @click="toggleSubMenu(subchild.id)"
                        >
                            {{ subchild.ueberschrift }}
                        </div>
                        <div
                            class="flex flex-col gap-8"
                            :class="{ hidden: activeSubMenu !== subchild.id }"
                        >
                            <template v-for="subsubchild in subchild.children">
                                <a
                                    v-if="
                                        subsubchild.__typename ==
                                        'navigation_navlink_BlockType'
                                    "
                                    :href="subsubchild.linkfeld.url"
                                    class="text-f5 font-secondary !font-bold !not-italic hover-text-color-primary"
                                >
                                    {{ subsubchild.linkfeld.text }}
                                </a>
                            </template>
                        </div>
                    </template>
                </div>
            </template>

            <div class="flex flex-col gap-16">
                <div class="flex flex-col gap-6 top-bar">
                    <template v-for="kleinerLink in props.data.kleineLinks">
                        <a
                            class="flex gap-2 items-center"
                            :href="kleinerLink.linkfeld.url"
                            v-if="kleinerLink.linkfeld.customText"
                        >
                            <i
                                class="text-large"
                                v-if="kleinerLink.iconName"
                                :class="[
                                    CONFIG_OPTIONS.global.iconStyle,
                                    'fa-' + kleinerLink.iconName,
                                ]"
                            ></i>
                            {{ kleinerLink.linkfeld.customText }}
                        </a>
                    </template>
                </div>

                <div class="flex flex-col gap-4">
                    <a
                        href=""
                        v-for="(button, index) in props.data.headerButtons"
                        class="flex gap-2 items-center"
                        :class="[
                            index === 0
                                ? CONFIG_OPTIONS.header.primaryButtonClass
                                : CONFIG_OPTIONS.header.secondaryButtonClass,
                        ]"
                    >
                        <i
                            v-if="button.iconName"
                            :class="[
                                CONFIG_OPTIONS.global.iconStyle,
                                'fa-' + button.iconName,
                            ]"
                        ></i>
                        {{ button.linkfeld.customText }}
                    </a>
                </div>

                <div class="flex justify-between items-center">
                    <div class="flex gap-3 text-tiny">
                        <div class="border-r border-black pr-3">DE</div>
                        <div class="border-r border-black pr-3">EN</div>
                        <div class="border-r border-black pr-3">FR</div>
                        <div class="">IT</div>
                    </div>

                    <div class="flex gap-3">
                        <template v-for="kleinerLink in props.data.kleineLinks">
                            <a
                                :href="kleinerLink.linkfeld.url"
                                v-if="
                                    !kleinerLink.linkfeld.customText &&
                                    kleinerLink.iconName
                                "
                                class="border-r border-black pr-3"
                            >
                                <i
                                    class="text-large"
                                    :class="[
                                        CONFIG_OPTIONS.global.iconStyle,
                                        'fa-' + kleinerLink.iconName,
                                    ]"
                                ></i>
                            </a>
                        </template>
                        <a href="">
                            <i
                                class="fa-magnifying-glass text-large"
                                :class="[CONFIG_OPTIONS.global.iconStyle]"
                            ></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { CONFIG_OPTIONS } from "@src/globals";
import { navigate } from "astro/virtual-modules/transitions-router.js";

const props = defineProps<{
    data: any;
    layout: number;
}>();

const dropdownMenues = ref([]);
const activeMenu = ref(null);
const activeSubMenu = ref(null);
const mobileMenuOpen = ref(false);

if (props.data.navigation) {
    props.data.navigation.forEach((item) => {
        if (item.__typename === "navigation_navgruppe_BlockType") {
            dropdownMenues.value.push(item);
        }
    });
}

function toggleMenu(id) {
    if (activeMenu.value === id) {
        activeMenu.value = null;
        activeSubMenu.value = null;
    } else {
        activeMenu.value = id;
    }
}

function toggleSubMenu(id) {
    if (activeSubMenu.value === id) {
        activeSubMenu.value = null;
    } else {
        activeSubMenu.value = id;
    }
}

function toggleMobileMenu() {
    mobileMenuOpen.value = !mobileMenuOpen.value;

    activeMenu.value = null;
    activeSubMenu.value = null;
}
</script>

<style lang="scss" scoped></style>
